import { differenceInDays } from 'date-fns';

import {
  SET_CHART_INTERVAL,
  SET_DATE_RANGE,
  SET_METRIX_BY_DT,
  SET_PRIMARY_METRIC,
  SET_SECONDARY_METRIC,
  START_DATA_LOADING
} from '../actions';
import {
  PULL_PRIMARY_CHART_DATA,
  PULL_SECONDARY_CHART_DATA,
  SET_DASHBOARD_ORDER_EVENT_TYPE,
  SET_INSIGHTS_WIDGET_DATA,
  SET_INSIGHTS_WIDGET_LOADING,
  SET_SALES,
  SET_TOTALS
} from '../saga/dashboard';

const initialState = {
  sales: {},
  compareSales: null,
  metricsByDt: [],
  secondaryMetricsData: null,
  salesLoading: false,
  totals: {},
  compareTotals: null,
  totalsLoading: false,
  interval: 'Hourly',
  primaryMetric: 'sales',
  secondaryMetric: null,
  disabledChartInterval: null,
  chartLoading: false,
  secondaryChartLoading: false,
  orderEventType: '',
  insightsWidgetData: null,
  insightsWidgetLoading: false
};

export const dashboardSelector = state => state.dashboard;

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOTALS: {
      const { totals, compareTotals } = action;
      return {
        ...state,
        totals,
        compareTotals,
        totalsLoading: false
      };
    }
    case SET_SALES: {
      const { sales, compareSales } = action;
      return {
        ...state,
        sales,
        compareSales,
        salesLoading: false
      };
    }
    case SET_METRIX_BY_DT: {
      const { metricsByDt, isPrimary } = action;
      const dataKeys = [
        { data: 'metricsByDt', loading: 'chartLoading' },
        { data: 'secondaryMetricsData', loading: 'secondaryChartLoading' }
      ];
      if (!isPrimary) dataKeys.reverse();
      const [currentMetricKeys, anotherMetricKeys] = dataKeys;
      const newState = {
        ...state,
        [currentMetricKeys.loading]: false,
        [currentMetricKeys.data]: metricsByDt
      };
      if (state[anotherMetricKeys.loading]) newState[anotherMetricKeys.data] = [];
      return newState;
    }
    case START_DATA_LOADING: {
      return {
        ...state,
        sales: {},
        totalsLoading: true,
        salesLoading: true
      };
    }
    case SET_CHART_INTERVAL: {
      const { interval } = action;

      return {
        ...state,
        chartLoading: true,
        interval
      };
    }
    case SET_PRIMARY_METRIC: {
      const { metric } = action;
      return { ...state, primaryMetric: metric };
    }
    case SET_SECONDARY_METRIC: {
      const { metric } = action;
      return {
        ...state,
        secondaryMetric: metric
      };
    }
    case PULL_SECONDARY_CHART_DATA:
      return { ...state, secondaryChartLoading: true };
    case PULL_PRIMARY_CHART_DATA:
      return { ...state, chartLoading: true };
    case SET_DATE_RANGE: {
      const { dateRange } = action;
      const rangeLength = differenceInDays(dateRange.to, dateRange.from);
      let { interval } = state;
      const isLongRange = rangeLength >= 6;
      if (state.interval === 'Hourly' && isLongRange) interval = 'Daily';
      if (!rangeLength) interval = 'Hourly';
      return { ...state, disabledChartInterval: isLongRange ? 'Hourly' : null, interval };
    }
    case SET_DASHBOARD_ORDER_EVENT_TYPE: {
      return { ...state, orderEventType: action.payload };
    }
    case SET_INSIGHTS_WIDGET_DATA: {
      return { ...state, insightsWidgetData: action.payload };
    }
    case SET_INSIGHTS_WIDGET_LOADING: {
      return { ...state, insightsWidgetLoading: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default dashboard;
