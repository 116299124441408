import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { IconButton } from '@material-ui/core';

import NewTabReference from '@components/common/NewTabReference';
import BlackTooltip from '@components/common/BlackTooltip';
import { BackIconButton, CheckBox, GenerateInputs, RoundedButton } from '@componentsV2';
import { isDemoBuild } from '@utils/build';
import { addInputFocusHandler } from '@utils/forms';
import Colors from 'styles/colors';
import { ImageObserver } from 'seller/containers/observer';
import { EnvelopeIcon, InfoIcon, LockIcon, UserIcon } from '@components/icons';
import ROUTES from 'seller/routing';
import signinWallpaper from 'seller/pages/login-window-v2/signin_wallpaper.png';
import { signupAction } from 'seller/store/actions';
import { useTextInput } from 'seller/pages/profile/Hooks';
import { loginSelector } from 'seller/store/reducers';
import { utSignup2Finished, utSignup2View } from '@utils/userTracking';
import { getCheckboxOnKeyPress } from './utils';

function Registration({ signup, history, location }) {
  const { token, username } = useSelector(loginSelector);
  if ((token && username) || isDemoBuild()) history.push(`${ROUTES.home}${location.search}`);

  useEffect(() => {
    utSignup2View();
  }, []);

  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const recaptchaRef = React.useRef();
  const [captchaToken, setToken] = React.useState('');
  const searchParams = new URLSearchParams(location.search);
  const promo = searchParams.get('promo');

  const handleChange = value => {
    setToken(value);
  };
  const [termsCheckbox, setTermsCheckbox] = useState(false);
  const [haveSellerAccountChecked, setHaveSellerAccountChecked] = useState(false);
  const [haveSellerAccountPopup, setHaveSellerAccountPopup] = useState(false);

  const userName = useTextInput('');
  const email = useTextInput('');
  const password = useTextInput('');

  const inputRefs = useRef([]);

  const formFilled = userName.value && email.value && password.value && termsCheckbox;

  const onRegistration = () => {
    if (!formFilled) return;
    if (
      !email.value ||
      !password.value ||
      !termsCheckbox ||
      (RECAPTCHA_SITE_KEY && !captchaToken)
    ) {
      return;
    }
    if (!haveSellerAccountChecked) {
      setHaveSellerAccountPopup(true);
      return;
    }

    const onFailedSignup = () => {
      if (!RECAPTCHA_SITE_KEY) return;

      setToken('');
      recaptchaRef.current.reset();
    };

    signup(
      userName.value,
      email.value,
      password.value,
      captchaToken,
      promo,
      () => {
        history.push(`${ROUTES.welcome}${location.search}`);
        utSignup2Finished();
      },
      onFailedSignup
    );
  };

  const inputsList = [
    { icon: UserIcon, label: 'Name', props: userName, hint: 'Write your name' },
    { icon: EnvelopeIcon, label: 'email', props: email, hint: 'Write your email' },
    {
      icon: LockIcon,
      type: 'password',
      props: { ...password, inputProps: { autoComplete: 'new-password' } },
      hint: 'Set up your password'
    },
    { props: {} },
    { props: {} }
  ];

  addInputFocusHandler(inputsList, onRegistration, inputRefs);

  const haveSellerAccountCheckBoxData = inputsList.pop();
  const termsCheckBoxData = inputsList.pop();

  const TermsContent = (
    <span className="text-sm">
      I agree with the
      <NewTabReference
        className="text-yellow font-bold hover:underline ml-1"
        href="https://sellermetrix.com/terms-of-service/"
      >
        Terms & Conditions
      </NewTabReference>
    </span>
  );

  const recaptchaView = RECAPTCHA_SITE_KEY ? (
    <div className="mt-4">
      <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_SITE_KEY} onChange={handleChange} />
    </div>
  ) : null;

  return (
    <ImageObserver className="flex bg white w-full h-full">
      <div className="px-8 sm:px-12 pt-8 sm:pt-12 text-gray-darkest flex flex-col items-center sm:justify-between bg-white md:w-2/4 w-full box-shadow-default min-h-screen z-0">
        <Link to={ROUTES.root} className="self-start mb-12 sm:mb-0">
          <BackIconButton />
        </Link>
        <div className="flex flex-col items-start w-full sm:w-10/12 sm:sm-w-448px">
          <p className="font_40px font-bold mb-4">Sign up</p>
          <form className="w-full">
            <div className="flex flex-col gap-3">
              <GenerateInputs data={inputsList} styleV2 />
            </div>
            <CheckBox
              label={TermsContent}
              classNameContainer="mt-2"
              value={termsCheckbox}
              onCheckHandler={check => setTermsCheckbox(check)}
              onKeyPress={getCheckboxOnKeyPress(termsCheckbox, setTermsCheckbox, termsCheckBoxData)}
              ref={termsCheckBoxData.props.inputRef}
            />
            <CheckBox
              label={
                <BlackTooltip
                  title="This platform is specifically designed for Amazon Sellers.
                    You'll need to connect your Amazon Seller Central account after signing up to
                    access the platform's functionality."
                  placement="top"
                  arrow
                  open={haveSellerAccountPopup}
                >
                  <div
                    className="flex items-center"
                    onMouseEnter={() => setHaveSellerAccountPopup(true)}
                    onMouseLeave={() => setHaveSellerAccountPopup(false)}
                  >
                    <span>I have an active Amazon Seller Central account</span>
                    <IconButton
                      className="ml-1 m-0 p-1"
                      onClick={e => {
                        e.stopPropagation();
                        setHaveSellerAccountPopup(true);
                      }}
                    >
                      <InfoIcon className="" fill={Colors.gray.dark} />
                    </IconButton>
                  </div>
                </BlackTooltip>
              }
              classNameContainer="mt-2"
              value={haveSellerAccountChecked}
              onCheckHandler={check => setHaveSellerAccountChecked(check)}
              onKeyPress={getCheckboxOnKeyPress(
                haveSellerAccountChecked,
                setHaveSellerAccountChecked,
                haveSellerAccountCheckBoxData
              )}
              ref={haveSellerAccountCheckBoxData.props.inputRef}
            />

            <RoundedButton
              text="Sign up"
              active={formFilled}
              onClick={() => onRegistration()}
              className="mt-4 w-full"
            />
            {recaptchaView}
          </form>
          <div className="flex justify-center items-center w-full mt-4">
            <div className="h-px bg-gray flex-grow" />
            <div className="mx-2 text-gray">or</div>
            <div className="h-px bg-gray flex-grow" />
          </div>
          <div className="mt-4 w-full flex items-center justify-center text-sm text-gray-dark font-bold">
            <p className="mr-1 inline-block">Already have an account?</p>
            <Link to={ROUTES.login} color="inherit" className="text-green-emerald font-bold">
              Sign in
            </Link>
          </div>
        </div>
        <div />
      </div>
      <div className="w-2/4 h-screen justify-start items-center hidden md:flex overflow-hidden">
        <img
          src={signinWallpaper}
          alt="signin-wallpaper"
          className="object-cover min-w-full min-h-full"
        />
      </div>
    </ImageObserver>
  );
}

Registration.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  signup: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    username: state.login.username
  };
}

export default connect(mapStateToProps, {
  signup: signupAction
})(Registration);
