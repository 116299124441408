export const PULL_USER_NOTIFICATIONS = 'PULL_USER_NOTIFICATIONS';
export const SET_USER_NOTIFICATIONS = 'SET_USER_NOTIFICATIONS';
export const MARK_NOTIFICATION_PROCESSED = 'MARK_NOTIFICATION_PROCESSED';

export const pullUserNotificationsAction = () => ({
  type: PULL_USER_NOTIFICATIONS
});

export const setUserNotificationsAction = notifications => ({
  type: SET_USER_NOTIFICATIONS,
  notifications
});

export const markNotificationProcessedAction = notificationId => ({
  type: MARK_NOTIFICATION_PROCESSED,
  notificationId
});
