import { SET_USER_NOTIFICATIONS } from 'seller/store/saga/userNotifications/action';

const initialState = {
  notifications: []
};

export const userNotificationsSelector = state => state.userNotifications;

const userNotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_NOTIFICATIONS:
      return { ...state, notifications: action.notifications };

    default:
      return state;
  }
};

export default userNotificationsReducer;
