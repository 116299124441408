import PropTypes from 'prop-types';
import { Dialog, IconButton } from '@material-ui/core';

import Colors from 'styles/colors';
import { CrossSolidIcon } from '../icons';
import { ModernCancelConfirmButtons } from '../buttons';
import { HEADER_HEIGHT } from '@sm/layout';
import { isSmallSize } from '@sm/WindowSizes';

const ModernDialog = ({
  open,
  title,
  content,
  onCancel,
  onConfirm,
  confirmActive,
  cancelText,
  confirmText,
  hideCrossIcon,
  hideCancelButton,
  fullWidth,
  maxWidth,
  fullScreenOnMobile = false
}) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      style={{ top: HEADER_HEIGHT }}
      fullScreen={isSmallSize() && fullScreenOnMobile}
    >
      <div className="p-6">
        <div className="flex items-center justify-between mb-8">
          <div className="text-large font-medium text-gray-darkest flex-grow">{title}</div>
          {!hideCrossIcon && (
            <IconButton className="p-1 ml-4 -mr-1" onClick={onCancel}>
              <CrossSolidIcon fill={Colors.gray.dark} />
            </IconButton>
          )}
        </div>
        {content}
      </div>
      <ModernCancelConfirmButtons
        onCancel={onCancel}
        onConfirm={onConfirm}
        cancelText={cancelText}
        confirmText={confirmText}
        confirmActive={confirmActive}
        hideCancelButton={hideCancelButton}
      />
    </Dialog>
  );
};

ModernDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  confirmActive: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  content: PropTypes.node.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  hideCrossIcon: PropTypes.bool,
  fullWidth: PropTypes.bool,
  hideCancelButton: PropTypes.bool,
  maxWidth: PropTypes.string,
  fullScreenOnMobile: PropTypes.bool
};

ModernDialog.defaultProps = {
  cancelText: 'Cancel',
  confirmText: 'Confirm',
  hideCrossIcon: false,
  fullWidth: false,
  hideCancelButton: false,
  maxWidth: undefined,
  fullScreenOnMobile: false
};

export default ModernDialog;
