import axios from 'axios/index';

import API, { rangeToAPI } from '@sm/api';
import { GLOBAL_MK } from '@sm/Flags';
import { prepareAgencyData } from 'agency/dashboard/utils';
import { SET_NETWORK_ACTIVITY, setNotificationMsgAction } from './index';

export const SET_AGENCY_CLIENTS = 'SET_AGENCY_CLIENTS';
export const SET_AGENCY_CLIENTS_FETCHING = 'SET_AGENCY_CLIENTS_FETCHING';
export const SET_AGENCY_CLIENTS_DATA = 'SET_AGENCY_CLIENTS_DATA';
export const SET_AGENCY_MARKETPLACES = 'SET_AGENCY_MARKETPLACES';
export const SET_AGENCY_CLIENT_SEARCH_KEYWORD = 'SET_AGENCY_CLIENT_SEARCH_KEYWORD';
export const SET_SELECTED_AGENCY_CLIENTS = 'SET_SELECTED_AGENCY_CLIENTS';

export const setAgencyClientsDataAction = payload => ({ type: SET_AGENCY_CLIENTS_DATA, payload });
export const setAgencyMarketplacesAction = payload => ({ type: SET_AGENCY_MARKETPLACES, payload });
export const setAgencyClientSearchKeywordAction = payload => ({
  type: SET_AGENCY_CLIENT_SEARCH_KEYWORD,
  payload
});
export const setSelectedAgencyClientsAction = payload => ({
  type: SET_SELECTED_AGENCY_CLIENTS,
  payload
});

const pullAgencyClientsDataByRange = async (currency, selectedMarketplaces, dateRange) => {
  const params = { ...rangeToAPI(dateRange), currency };
  if (selectedMarketplaces && selectedMarketplaces[0] !== GLOBAL_MK) {
    params.marketplaces = selectedMarketplaces.join(',');
  }
  const response = await axios.get(API.agency_clients_summary, { params });
  return response.data?.map(prepareAgencyData);
};

export const pullAgencyClientsData = () => {
  return (dispatch, getState) => {
    dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: true });
    const { dateRange, compareDateRange, selectedMarketplaces } = getState().selector;
    const currency = getState().user.profile?.defaultSettings?.currency;
    const dataByRangeData = pullAgencyClientsDataByRange(currency, selectedMarketplaces, dateRange);

    const dataByCompareRangeData = compareDateRange
      ? pullAgencyClientsDataByRange(currency, selectedMarketplaces, compareDateRange)
      : Promise.resolve([]);

    Promise.all([dataByRangeData, dataByCompareRangeData])
      .then(([main, compare]) => {
        dispatch(setAgencyClientsDataAction({ main, compare }));
      })
      .catch(error => {
        console.log('pullAgencyClientsDataByRange', error);
        dispatch(setNotificationMsgAction('Pull agency clients failed'));
      })
      .finally(() => {
        dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: false });
      });
  };
};

export const pullAgencyClients = () => {
  return (dispatch, getState) => {
    const { userId } = getState().user;
    dispatch({ type: SET_AGENCY_CLIENTS_FETCHING, payload: true });
    axios
      .get(API.agency_clients, {
        params: {
          userId
        }
      })
      .then(resp => {
        dispatch({ type: SET_AGENCY_CLIENTS, clients: resp.data });
      })
      .catch(error => {
        dispatch(setNotificationMsgAction(String(error)));
        dispatch({ type: SET_AGENCY_CLIENTS_FETCHING, payload: false });
      });
  };
};

export const sendClientInvite = (email, onFinished) => {
  return dispatch => {
    axios
      .post(API.agency_clients, { email })
      .then(res => {
        console.log(res.data);
        dispatch(pullAgencyClients());
        onFinished();
      })
      .catch(err => {
        onFinished();
        console.error(err);
      });
  };
};
