/* eslint-disable camelcase */
/* global Intercom */
const app_id = 'tvu3c3ho';

export const isIntercomEnabled = () => typeof Intercom !== 'undefined';

export const closeIntercomSession = () => {
  if (!isIntercomEnabled()) {
    return;
  }
  Intercom('shutdown');
};

export const startNewMessage = () => {
  if (!isIntercomEnabled()) {
    return;
  }
  Intercom('showNewMessage');
};

export const setIntercom = (email, company, name, user_hash) => {
  if (!user_hash) {
    closeIntercomSession();
    return;
  }
  if (!isIntercomEnabled()) {
    return;
  }

  const settings = {
    app_id,
    email,
    company,
    name,
    user_hash
  };

  window.intercomSettings = settings;
  Intercom('boot', settings);
};

export const setIntercomMessage = message => {
  if (!isIntercomEnabled()) return;
  Intercom('showNewMessage', message);
};

export const setIntercomErrorMessage = error => {
  setIntercomMessage(
    `Hi SellerMetrix Team, I'm having trouble connecting my account. Can you help me with that? Error: ${error}`
  );
};
