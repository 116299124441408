import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import classNames from 'classnames';

const BaseButton = ({ onClick, className, children }) => {
  return (
    <Button className={classNames('normal-case min-w-0', className)} onClick={onClick}>
      {children}
    </Button>
  );
};

BaseButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

BaseButton.defaultProps = {
  onClick: () => {},
  className: ''
};

export default BaseButton;
