import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosCall } from '@utils';
import API from '@sm/api';
import {
  MARK_NOTIFICATION_PROCESSED,
  PULL_USER_NOTIFICATIONS,
  setUserNotificationsAction
} from './action';

function* pullUserNotificationsSaga() {
  try {
    const { data } = yield call(axiosCall.get, API.notifications.list.web);
    yield put(setUserNotificationsAction(data));
  } catch (error) {
    yield put(setUserNotificationsAction([]));
  }
}

function* markNotificationProcessedSaga({ notificationId }) {
  try {
    const { data } = yield call(axiosCall.patch, API.notifications.list.web, {
      notification_id: notificationId
    });
    yield put(setUserNotificationsAction(data));
  } catch (error) {
    yield put(setUserNotificationsAction([]));
  }
}

export function* notificationsSagaWatcher() {
  yield takeLatest(PULL_USER_NOTIFICATIONS, pullUserNotificationsSaga);
  yield takeLatest(MARK_NOTIFICATION_PROCESSED, markNotificationProcessedSaga);
}
