import PropTypes from 'prop-types';

import Colors from 'styles/colors';

const ICON_PROP_TYPES = {
  className: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

export const BackIcon = props => {
  const { width, height } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="51" height="51" rx="11.5" stroke="#757575" strokeOpacity="0.5" />
      <path
        d="M29.6941 32.9028C29.9382 32.6587 29.9604 32.2768 29.7607 32.0077L29.6941 31.9306L23.7639 26L29.6941 20.0695C29.9382 19.8254 29.9604 19.4435 29.7607 19.1743L29.6941 19.0972C29.4501 18.8531 29.0681 18.831 28.799 19.0307L28.7219 19.0972L22.3052 25.5139C22.0611 25.758 22.0389 26.1399 22.2386 26.409L22.3052 26.4862L28.7219 32.9028C28.9904 33.1713 29.4257 33.1713 29.6941 32.9028Z"
        fill="#757575"
        fillOpacity="0.5"
      />
    </svg>
  );
};

BackIcon.defaultProps = {
  width: '52',
  height: '52'
};

BackIcon.propTypes = ICON_PROP_TYPES;

export const HelpIcon = props => {
  const { width, height, fill, className } = props;
  return (
    <svg
      className={className}
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="HelpOutlineOutlinedIcon"
      width={width}
      height={height}
      fill={fill}
    >
      <path d="M11 18h2v-2h-2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8m0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4" />
    </svg>
  );
};

HelpIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  className: PropTypes.string
};

HelpIcon.defaultProps = {
  width: '16',
  height: '16',
  fill: Colors.white.default,
  className: ''
};

BackIcon.propTypes = ICON_PROP_TYPES;
