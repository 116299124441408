import { buildParamsCompare } from 'seller/utils/buildParams';
import { pullPrimaryChartDataAction, pullSecondaryChartDataAction } from '../saga/dashboard';

export const SET_PRIMARY_METRIC = 'SET_PRIMARY_METRIC';
export const SET_SECONDARY_METRIC = 'SET_SECONDARY_METRIC';
export const SET_CHART_INTERVAL = 'SET_CHART_INTERVAL';
export const SET_METRIX_BY_DT = 'SET_METRIX_BY_DT';

export const setMetrixByDtAction = ({ metricsByDt, isPrimary }) => ({
  type: SET_METRIX_BY_DT,
  metricsByDt,
  isPrimary
});

export const pullIfSecondaryMetric = (
  dispatch,
  params,
  compareParams,
  interval,
  secondaryMetric
) => {
  if (secondaryMetric) {
    dispatch(
      pullSecondaryChartDataAction({
        params,
        compareParams,
        interval,
        metric: secondaryMetric,
        isPrimary: false
      })
    );
  }
};

export const setIntervalChanged = (interval = 'Day') => {
  return (dispatch, getState) => {
    const { primaryMetric, secondaryMetric } = getState().dashboard;
    dispatch({ type: SET_CHART_INTERVAL, interval });
    const { params, compareParams } = buildParamsCompare(getState(), dispatch);
    if (params.temp_bookmark_expired) return;
    dispatch(
      pullPrimaryChartDataAction({
        params,
        compareParams,
        interval,
        metric: primaryMetric,
        isPrimary: true
      })
    );
    pullIfSecondaryMetric(dispatch, params, compareParams, interval, secondaryMetric);
  };
};

export const setMetricChanged = (metric, isPrimary) => {
  return (dispatch, getState) => {
    const { interval } = getState().dashboard;
    dispatch({ type: isPrimary ? SET_PRIMARY_METRIC : SET_SECONDARY_METRIC, metric });
    const { params, compareParams } = buildParamsCompare(getState(), dispatch);
    if (params.temp_bookmark_expired) return;
    if (isPrimary) {
      dispatch(pullPrimaryChartDataAction({ params, compareParams, interval, metric, isPrimary }));
    } else
      dispatch(
        pullSecondaryChartDataAction({ params, compareParams, interval, metric, isPrimary })
      );
  };
};
