export const SET_MARKETPLACE = 'SET_MARKETPLACE';
export const SET_DATE_RANGE = 'SET_DATE_RANGE';
export const SET_COMPARE_DATE_RANGE = 'SET_COMPARE_DATE_RANGE';
export const SET_COMPARE_MODE = 'SET_COMPARE_MODE';
export const SET_SELECTED_MARKETPLACES = 'SET_SELECTED_MARKETPLACES';

export const START_DATA_LOADING = 'START_DATA_LOADING';

export const setMarketplaceAction = (marketplaceID, skipClearSelectedProducts) => {
  return { type: SET_MARKETPLACE, marketplaceID, skipClearSelectedProducts };
};

export const setDateRangeAction = dateRange => {
  return { type: SET_DATE_RANGE, dateRange };
};

export const setCompareDateRangeAction = compareDateRange => {
  return { type: SET_COMPARE_DATE_RANGE, compareDateRange };
};

export const setSelectedMarketplacesAction = payload => ({
  type: SET_SELECTED_MARKETPLACES,
  payload
});

export const setCompareModeAction = payload => ({
  type: SET_COMPARE_MODE,
  payload
});
