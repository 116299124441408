import ReactDOM from 'react-dom';

import 'typeface-roboto';
import 'typeface-playfair-display';
import store, { saga } from 'seller/store';
import { isProduction } from '@utils/build';
import {
  initAxiosRequestsInterceptor,
  initAxiosResponseInterceptor
} from '@utils/axiosInterceptors';
import 'styles/tailwind.css';
import 'index.css';
import App from 'seller/App';
import { initSentry, sendTextError } from '@utils';
import { initUserTracking } from '@utils/userTracking';
import sagaWatcher from 'seller/store/SagaWatcher';
import { addSentSentryErrorTypeAction } from 'seller/store/actions';
import { getHandleAppVersion } from 'seller/utils/appVersionControl';
import {
  cleanupSystemNotificationsAction,
  handleSystemErrorsAction
} from 'seller/store/saga/system';
import { handleGlobalSystemNotificationAction } from 'seller/store/saga/globalSystemNotification';

function noop() {}

initUserTracking();

if (isProduction()) {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

initSentry(store, addSentSentryErrorTypeAction);

initAxiosRequestsInterceptor(store);
initAxiosResponseInterceptor(
  sendTextError,
  store,
  getHandleAppVersion(store),
  cleanupSystemNotificationsAction,
  handleSystemErrorsAction,
  handleGlobalSystemNotificationAction
);

ReactDOM.render(<App />, document.getElementById('root'));

saga.run(sagaWatcher);
