import { put, takeLatest } from '@redux-saga/core/effects';

import { SEND_USER_FEEDBACK } from './action';
import { axiosCall } from '@utils';
import API from '@sm/api';
import { setNotificationMsgAction } from 'seller/store/actions';

export function* sendUserFeedbackSaga(action) {
  const { featureName, positive, data, relatedDataId } = action;
  try {
    yield axiosCall.post(API.user_feedback.root, {
      feature_name: featureName,
      positive,
      content: data,
      related_data_id: relatedDataId
    });
  } finally {
    yield put(setNotificationMsgAction('Your feedback has been sent!', 'success'));
  }
}

export function* userFeedbackSagaWatcher() {
  yield takeLatest([SEND_USER_FEEDBACK], sendUserFeedbackSaga);
}
