export const getFilter = (searchableFields, extraKey) => (data, keyword) => {
  const result = [];
  const checkField = (row, field) => {
    const value = extraKey ? row?.[extraKey]?.[field] : row?.[field];
    return `${value}`.toLowerCase().includes(keyword.toLowerCase());
  };
  const checkFields = item => {
    if (item.children) {
      result.push(
        ...item.children.filter(child => searchableFields.some(field => checkField(child, field)))
      );
    }
    if (searchableFields.some(field => checkField(item, field))) result.push(item);
  };
  data.forEach(checkFields);
  return result;
};

export const boolToText = value => (value ? 'Yes' : 'No');
export const textToBool = value => value === 'Yes';

export const BINARY_OPTIONS = ['Yes', 'No'];
