import { buildParamsCompare } from 'seller/utils/buildParams';
import {
  pullNotesAction,
  pullPrimaryChartDataAction,
  pullSalesAction,
  pullTotalsAction
} from '../saga/dashboard';
import { pullIfSecondaryMetric } from './chart.actions';
import { START_DATA_LOADING } from './selector.actions';

// eslint-disable-next-line import/prefer-default-export
export const updateDashboard = () => {
  return (dispatch, getState) => {
    const { interval, primaryMetric, secondaryMetric } = getState().dashboard;
    const { params, compareParams } = buildParamsCompare(getState(), dispatch);
    if (params.temp_bookmark_expired) return;

    dispatch({ type: START_DATA_LOADING });
    dispatch(pullTotalsAction({ params, compareParams }));
    dispatch(pullSalesAction(params, compareParams));

    dispatch(
      pullPrimaryChartDataAction({
        params,
        compareParams,
        interval,
        metric: primaryMetric,
        isPrimary: true
      })
    );
    pullIfSecondaryMetric(dispatch, params, compareParams, interval, secondaryMetric);
    dispatch(pullNotesAction({ params }));
  };
};
