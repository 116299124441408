import { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';

import { HelpIcon } from '@componentsV2/icons/icons';
import ModernDialog from '@components/dialogs/ModernDialog';
import Colors from 'styles/colors';

const HelpDialog = ({ title, content }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="ml-auto">
      <IconButton onClick={handleOpen} className="ml-auto p-2 mr-4 rounded-lg">
        <HelpIcon width="28" height="28" fill={Colors.white.default} className="opacity-75" />
      </IconButton>
      <ModernDialog
        open={open}
        onCancel={handleClose}
        onConfirm={handleClose}
        title={title}
        maxWidth="md"
        fullWidth
        hideCancelButton
        confirmText="Ok"
        content={content}
        fullScreenOnMobile
      />
    </div>
  );
};

HelpDialog.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired
};

export default HelpDialog;
