import {
  AddressCardIcon,
  AmazonIcon,
  CartIcon,
  ChartLineIcon,
  CogsIcon,
  EnvelopeIcon,
  ExcelIcon,
  // ExcelIcon,
  ExchangeAltIcon,
  HistoryIcon,
  HomeIcon,
  PowerOffIcon,
  ProductsIcon,
  UserIcon,
  UserSecretIcon,
  VatIcon,
  WalletIcon
} from '@components';
import { isFullAccessForFree, isIncompleted, isOnboarding } from '@sm/api';
import { isDemoBuild, isProduction } from '@utils/build';
import ROUTES from './routing';

export const ORDERS_APP = 'Orders';
export const PRODUCTS_APP = 'Products';
export const REFUNDS_APP = 'Refunds';
export const AGENCY_APP = 'Agency';
export const DASHBOARD_APP = 'Dashboard';
export const EMAIL_TOOL_APP = 'Email Follow-Up';
export const INVENTORY_APP = 'Inventory';
export const REPORTS_APP = 'Reports';
export const EXPENSES_APP = 'Expenses';
export const ADVERTISING_APP = 'Advertising';
export const AMAZON_TRAFFIC_APP = 'Amazon Traffic';
export const MONTHLY_BRAND_REVIEW_APP = 'Monthly Brand Review';
export const PNL_APP = 'Profit and Loss';
export const INSIGHTS_APP = 'Insights';

const DASHBOARD_APP_ID = 0;
const PRODUCTS_APP_ID = 1;
const ORDERS_APP_ID = 2;
const REFUNDS_APP_ID = 3;
const BA_AGENCY_AP_ID = 4;
const AGENCY_AP_ID = 5;
const EMAIL_TOOL_APP_ID = 6;
const INVENTORY_APP_ID = 7;
const REPORTS_APP_ID = 8;
const EXPENSES_APP_ID = 9;
const ADVERTISING_APP_ID = 10;
const AMAZON_TRAFFIC_APP_ID = 11;
const MONTHLY_BRAND_REVIEW_APP_ID = 12;
const PNL_APP_ID = 13;
const INSIGHTS_APP_ID = 14;
const GO_BACK_TO_AGENCY_ID = 15;

export const APP_MENU_ITEMS = [
  {
    id: DASHBOARD_APP_ID,
    title: DASHBOARD_APP,
    desc: 'An overview of your business',
    img: HomeIcon,
    link: ROUTES.home
  },
  {
    id: PRODUCTS_APP_ID,
    title: PRODUCTS_APP,
    desc: 'Setup and manage your cogs',
    img: ProductsIcon,
    link: ROUTES.products
  },
  {
    id: ORDERS_APP_ID,
    title: ORDERS_APP,
    desc: 'List, arrange & download your orders',
    img: CartIcon,
    link: ROUTES.orders
  },
  /*
   FIXME: not using now
  { id: 3, title: 'Advertising', desc: 'Get the most out of your Amazon ads', img: AdsIcon },
  */
  {
    id: REFUNDS_APP_ID,
    title: REFUNDS_APP,
    desc: 'All your refunds and product returns',
    img: ExchangeAltIcon,
    link: ROUTES.refunds
  },
  {
    id: BA_AGENCY_AP_ID,
    title: AGENCY_APP,
    desc: 'Your agency dashboard',
    img: UserSecretIcon,
    onClick: history => {
      window.sessionStorage.clear();
      history.push(ROUTES.home);
      history.go();
    }
  },
  {
    id: AGENCY_AP_ID,
    title: AGENCY_APP,
    desc: 'An overview of your business',
    img: UserSecretIcon,
    link: ROUTES.home
  },
  {
    id: EMAIL_TOOL_APP_ID,
    title: EMAIL_TOOL_APP,
    desc: 'Email automation & review requests',
    img: EnvelopeIcon,
    link: ROUTES.email_tool.root
  },
  {
    id: INVENTORY_APP_ID,
    title: INVENTORY_APP,
    desc: 'Inventory description', // FIXME: Replace with correctly data
    img: EnvelopeIcon,
    link: ROUTES.inventory
  },
  // {
  //   id: REPORTS_APP_ID,
  //   title: REPORTS_APP,
  //   desc: 'Analyze key performance indicators',
  //   img: ExcelIcon,
  //   link: ROUTES.reports.root
  // },
  {
    id: EXPENSES_APP_ID,
    title: EXPENSES_APP,
    desc: 'Manage your expenses',
    img: WalletIcon,
    link: ROUTES.expenses.root
  },
  {
    id: AMAZON_TRAFFIC_APP_ID,
    title: AMAZON_TRAFFIC_APP,
    desc: 'Find what search terms work for your brand',
    img: AmazonIcon,
    link: ROUTES.amazonTraffic.root
  },
  {
    id: MONTHLY_BRAND_REVIEW_APP_ID,
    title: MONTHLY_BRAND_REVIEW_APP,
    desc: 'Review your brand performance',
    img: HistoryIcon,
    link: ROUTES.monthlyBrandReview.root
  },
  {
    id: PNL_APP_ID,
    title: PNL_APP,
    desc: 'Analyze key performance indicator',
    img: ExcelIcon,
    link: ROUTES.pnlDashboard.root
  },
  {
    id: INSIGHTS_APP_ID,
    title: INSIGHTS_APP,
    desc: 'Get insights on your business',
    img: ChartLineIcon,
    link: ROUTES.insights.root,
    beta: true
  },
  {
    id: GO_BACK_TO_AGENCY_ID,
    title: 'Back to Agency View',
    desc: 'An overview of your business',
    img: UserSecretIcon,
    onClick: () => {
      sessionStorage.removeItem('userId');
      sessionStorage.removeItem('hide');
      sessionStorage.removeItem('isOpenedFromAgencyTab');
      if (isDemoBuild()) {
        window.location = ROUTES.demo;
        return;
      }
      window.location.href = `${window.location.protocol}//${window.location.host}/app`;
    }
  }
  // {
  //   id: ADVERTISING_APP_ID,
  //   title: ADVERTISING_APP,
  //   desc: 'Manage your advertising',
  //   img: WalletIcon,
  //   link: ROUTES.advertising.root
  // }
];

const APP_MENU_MAP = {
  agency: [AGENCY_AP_ID, INSIGHTS_APP_ID],
  ba: [
    DASHBOARD_APP_ID,
    PRODUCTS_APP_ID,
    ORDERS_APP_ID,
    REFUNDS_APP_ID,
    BA_AGENCY_AP_ID,
    EMAIL_TOOL_APP_ID,
    ADVERTISING_APP_ID,
    EXPENSES_APP_ID,
    AMAZON_TRAFFIC_APP_ID,
    MONTHLY_BRAND_REVIEW_APP_ID,
    INSIGHTS_APP_ID,
    GO_BACK_TO_AGENCY_ID
  ],
  regular: [
    DASHBOARD_APP_ID,
    PRODUCTS_APP_ID,
    ORDERS_APP_ID,
    REFUNDS_APP_ID,
    EMAIL_TOOL_APP_ID,
    ADVERTISING_APP_ID,
    EXPENSES_APP_ID,
    AMAZON_TRAFFIC_APP_ID,
    MONTHLY_BRAND_REVIEW_APP_ID,
    INSIGHTS_APP_ID,
    GO_BACK_TO_AGENCY_ID
  ],
  onboarding: []
};

if (!isProduction()) APP_MENU_MAP.regular.push(REPORTS_APP_ID);

const getAppMenuItems = appIdList => APP_MENU_ITEMS.filter(item => appIdList.includes(item.id));

const getAppMenuIds = profile => {
  if (isIncompleted(profile) || isOnboarding(profile)) return APP_MENU_MAP.onboarding;
  const getApps = () => {
    if (profile.agency) return APP_MENU_MAP.agency;
    if (profile.ba) return APP_MENU_MAP.ba;
    return APP_MENU_MAP.regular;
  };
  let result = getApps();
  if (!profile.amazon_traffic_page) result = result.filter(item => item !== AMAZON_TRAFFIC_APP_ID);
  if (profile.no_marketplaces) {
    result = result.filter(
      item => ![EXPENSES_APP_ID, EMAIL_TOOL_APP_ID, AMAZON_TRAFFIC_APP_ID].includes(item)
    );
  }
  if (!profile.monthly_brand_review) {
    result = result.filter(item => item !== MONTHLY_BRAND_REVIEW_APP_ID);
  }
  if (profile.pnl_dashboard_start) result.push(PNL_APP_ID);
  if (!profile.insights) result = result.filter(item => item !== INSIGHTS_APP_ID);
  const isOpenedFromAgencyTab = sessionStorage.getItem('isOpenedFromAgencyTab') === 'true';
  if (!isOpenedFromAgencyTab) result = result.filter(item => item !== GO_BACK_TO_AGENCY_ID);
  return result;
};

export const getAppMenu = profile => getAppMenuItems(getAppMenuIds(profile));

const USER_MENU_ITEMS = [
  {
    id: 0,
    title: 'Profile',
    desc: 'Manage your account preferences',
    img: UserIcon,
    link: ROUTES.profile.general
  },
  {
    id: 1,
    title: 'Seller Accounts',
    desc: 'Connect more Seller Central accounts',
    img: CogsIcon,
    link: ROUTES.accounts
  },
  {
    id: 2,
    title: 'Plans & Billing',
    desc: 'Manage your bills & payment option',
    img: WalletIcon,
    link: ROUTES.plans
  },
  {
    id: 3,
    title: 'Plans & Billing',
    desc: 'Manage your bills & payment option',
    img: WalletIcon,
    link: ROUTES.billing
  },
  {
    id: 4,
    title: 'Clients',
    desc: 'Manage and invite new clients',
    link: ROUTES.users,
    img: AddressCardIcon
  },
  {
    id: 5,
    title: 'VAT',
    desc: 'Configure your VAT settings',
    img: VatIcon,
    link: ROUTES.vat
  },
  {
    id: 6,
    title: 'Log Out',
    desc: 'Stay safe, we’ll see you next time',
    img: PowerOffIcon,
    logout: true
  }
];

const USER_MENU_MAP = {
  agency: [ROUTES.profile.general, ROUTES.users],
  regular: [ROUTES.profile.general, ROUTES.accounts],
  plansBilling: [ROUTES.plans, ROUTES.billing],
  onboarding: []
};

const getUserMenuItems = linkList =>
  USER_MENU_ITEMS.filter(item => item.logout || linkList.includes(item.link));

const getUserMenuLinks = profile => {
  if (isIncompleted(profile) || isOnboarding(profile)) return USER_MENU_MAP.onboarding;
  const result = [...(profile.agency ? USER_MENU_MAP.agency : USER_MENU_MAP.regular)];
  if (profile.billing && !isFullAccessForFree(profile)) {
    result.push(profile?.subscription?.cc ? ROUTES.billing : ROUTES.plans);
  }
  if (profile.useVAT && !profile.agency) result.push(ROUTES.vat);
  return result;
};

export const getUserMenu = profile => getUserMenuItems(getUserMenuLinks(profile));
