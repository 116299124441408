import PropTypes from 'prop-types';
import classNames from 'classnames';

import { HEADER_HEIGHT } from '@sm/layout';
import { FontButton } from '../buttons';
import LogoImg from './sellermetrix-transparent.png';
import HelpDialog from '@componentsV2/dialogs/HelpDialog';

const HeaderView = ({
  appMenu,
  onLogoClick,
  logo,
  rightButton,
  hideAppMenu,
  alignLogoLeft,
  helpContent
}) => {
  const getHelpDialog = () => {
    if (!helpContent) return null;
    return <HelpDialog title="Help" content={helpContent} />;
  };
  return (
    <div
      className="flex flex-row flex-shrink-0 bg-grad-2 lg:px-6 xxs:px-2 z-10 justify-between items-center relative sm-z-1600"
      style={{ height: HEADER_HEIGHT }}
    >
      <div className="flex items-center">{hideAppMenu ? null : appMenu}</div>
      <FontButton
        className={classNames('flex w-40 h-8 justify-center items-center inset-x-0', {
          'mr-auto sm:mx-auto sm:absolute': alignLogoLeft,
          'absolute mx-auto': !alignLogoLeft
        })}
        onClick={onLogoClick}
      >
        <img alt="logo" className="h-8" src={logo} />
      </FontButton>
      {getHelpDialog()}
      {rightButton}
    </div>
  );
};

HeaderView.propTypes = {
  appMenu: PropTypes.instanceOf(Object),
  onLogoClick: PropTypes.func,
  logo: PropTypes.string,
  rightButton: PropTypes.node,
  hideAppMenu: PropTypes.bool,
  alignLogoLeft: PropTypes.bool,
  helpContent: PropTypes.node
};

HeaderView.defaultProps = {
  logo: LogoImg,
  rightButton: null,
  onLogoClick: () => {},
  appMenu: null,
  hideAppMenu: false,
  alignLogoLeft: false,
  helpContent: null
};

export default HeaderView;
