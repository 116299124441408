import { call, cancelled, put, select, takeLatest, all } from 'redux-saga/effects';
import { addDays, isSameDay } from 'date-fns';

import API, { API_V2_1, API_V2_2, BETA_API, DASHBOARD_PRODUCT_LIST_PULLING_TIMEOUT } from '@sm/api';
import { dashboardSelector, userSelector } from 'seller/store/reducers';
import { prepareSales } from 'seller/utils/convertRawApiData';
import { axiosCall, getCancelToken } from '@utils';
import { setMetrixByDtAction, setNotificationMsgAction } from '../../actions';
import {
  PULL_INSIGHTS_WIDGET_DATA,
  PULL_NOTES,
  PULL_PRIMARY_CHART_DATA,
  PULL_SALES,
  PULL_SECONDARY_CHART_DATA,
  PULL_TOTALS,
  setInsightsWidgetDataAction,
  setInsightsWidgetLoadingAction,
  setNotesAction,
  setRefundsTotalsAction,
  setSalesAction,
  setTotalsAction
} from './action';
import { missedBookmarkHandler } from '../utils';

const replaceAdsProductsWithSpread = items => {
  items.forEach(item => {
    // eslint-disable-next-line no-param-reassign
    item.ads_products = item.ads_products_spread;
    if (item.children) replaceAdsProductsWithSpread(item.children);
  });
};

const getIsAdsProductsSpreadEnabled = profile => {
  return profile?.ads_products_spread && !sessionStorage.getItem('ads_products_spread_disabled');
};

function* pullSalesData(_params, cancelToken, profile, orderEventType) {
  const params = { ..._params };
  if (orderEventType) params.order_event_type = orderEventType;
  const { data } = yield axiosCall.get(
    profile?.beta ? BETA_API.products.sales : API_V2_1.products.sales,
    { params, cancelToken, timeout: DASHBOARD_PRODUCT_LIST_PULLING_TIMEOUT }
  );
  return data;
}

export function* pullSalesSaga(action) {
  const { cancelToken, cancelRequest } = getCancelToken();
  try {
    const { params, compareParams } = action;
    const { profile } = yield select(userSelector);
    const { orderEventType } = yield select(dashboardSelector);
    if (orderEventType) params.order_event_type = orderEventType;
    const [data, compareData] = yield all([
      call(pullSalesData, params, cancelToken, profile, orderEventType),
      compareParams
        ? call(pullSalesData, compareParams, cancelToken, profile, orderEventType)
        : null
    ]);
    if (getIsAdsProductsSpreadEnabled(profile)) {
      replaceAdsProductsWithSpread(data.products);
      if (compareData) replaceAdsProductsWithSpread(compareData.products);
    }
    yield put(setSalesAction(prepareSales(data), compareData && prepareSales(compareData)));
  } catch (error) {
    const errorHandled = yield call(missedBookmarkHandler, error);
    if (errorHandled) return;
    yield put(setNotificationMsgAction('Pull products failed'));
  } finally {
    if (yield cancelled()) cancelRequest();
  }
}

export function* pullChartDataSaga({ payload }) {
  const { cancelToken, cancelRequest } = getCancelToken();
  const { params, compareParams, interval, metric, isPrimary } = payload;
  // If the compare period ends on the day before the primary period starts, extend the primary
  // period to include the compare period
  if (compareParams && isSameDay(addDays(new Date(compareParams.to), 1), new Date(params.from))) {
    params.from = compareParams.from;
  }
  try {
    const response = yield axiosCall.get(API.metrix.chart, {
      params: { ...params, metric, interval },
      cancelToken
    });
    const metricsByDt = response.data && response.data.metrix ? response.data.metrix : [];
    yield put(setMetrixByDtAction({ metricsByDt, isPrimary }));
  } catch (error) {
    const errorHandled = yield call(missedBookmarkHandler, error);
    if (errorHandled) return;
    console.log('pullChartDataSaga error:', error);
    yield put(setNotificationMsgAction('Pull chart data failed'));
    yield put(setMetrixByDtAction({ metricsByDt: [], isPrimary }));
  } finally {
    if (yield cancelled()) cancelRequest();
  }
}

function* pullTotalsData(params, metrics, cancelToken, profile, orderEventType) {
  const requestParams = { ...params };
  if (metrics && metrics.length) requestParams.metrics = metrics.join();
  if (orderEventType) requestParams.order_event_type = orderEventType;
  const useBetaApi = profile?.beta;
  const regularApiUrl = sessionStorage.getItem('metrix/totals/2.2')
    ? API_V2_2.metrix.totals
    : API_V2_1.metrix.totals;
  const url = useBetaApi ? BETA_API.metrix.totals : regularApiUrl;
  const { data } = yield axiosCall.get(url, { params: requestParams, cancelToken });
  return data;
}

export function* pullTotalsSaga({ payload }) {
  const { profile } = yield select(userSelector);
  const { orderEventType } = yield select(dashboardSelector);
  const { params, compareParams, metrics, refunds } = payload;
  const { cancelToken, cancelRequest } = getCancelToken();
  try {
    const [data, compareData] = yield all([
      call(pullTotalsData, params, metrics, cancelToken, profile, orderEventType),
      compareParams
        ? call(pullTotalsData, compareParams, metrics, cancelToken, profile, orderEventType)
        : null
    ]);
    if (!refunds) {
      if (getIsAdsProductsSpreadEnabled(profile)) {
        replaceAdsProductsWithSpread([data]);
        if (compareData) replaceAdsProductsWithSpread([compareData]);
      }
      yield put(setTotalsAction(data, compareData));
    } else {
      yield put(setRefundsTotalsAction(data, compareData));
    }
  } catch (error) {
    const errorHandled = yield call(missedBookmarkHandler, error);
    if (errorHandled) return;
    yield put(setNotificationMsgAction('Pull totals failed'));
  } finally {
    if (yield cancelled()) {
      cancelRequest();
    }
  }
}

export function* pullNotesSaga({ payload }) {
  const { params, callback } = payload;
  const { cancelToken, cancelRequest } = getCancelToken();
  try {
    const { data } = yield axiosCall.get(API.notes, { params, cancelToken });
    yield put(setNotesAction(data));
  } catch (error) {
    const errorHandled = yield call(missedBookmarkHandler, error);
    if (errorHandled) return;
    console.log('pullNotesSaga error:', error);
    yield put(setNotificationMsgAction('Pull notes failed'));
  } finally {
    if (yield cancelled()) cancelRequest();
    if (callback) callback();
  }
}

export function* pullInsightsWidgetDataSaga() {
  try {
    yield put(setInsightsWidgetLoadingAction(true));
    const { data } = yield axiosCall.get(API.insights.insights_14_days.list);
    yield put(setInsightsWidgetDataAction(data));
  } catch (error) {
    yield put(setNotificationMsgAction('Pull insights widget data failed'));
    yield put(setInsightsWidgetDataAction({}));
  } finally {
    yield put(setInsightsWidgetLoadingAction(false));
  }
}

export function* dashboardSagaWatcher() {
  yield takeLatest(PULL_PRIMARY_CHART_DATA, pullChartDataSaga);
  yield takeLatest(PULL_SECONDARY_CHART_DATA, pullChartDataSaga);
  yield takeLatest(PULL_SALES, pullSalesSaga);
  yield takeLatest(PULL_TOTALS, pullTotalsSaga);
  yield takeLatest(PULL_NOTES, pullNotesSaga);
  yield takeLatest(PULL_INSIGHTS_WIDGET_DATA, pullInsightsWidgetDataSaga);
}
