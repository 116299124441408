import { getSagaWatcher, sendError } from '@utils';
import { refundsSagaWatcher, searchProductsSagaWatcher } from './actions';
import { userPreferencesSagaWatcher } from './saga/userPreferences';
import { emailToolSagaWatcher } from './saga/emailTool';
import { ordersSagaWatcher } from './saga/orders';
import { productsSagaWatcher } from './saga/products';
import { dashboardSagaWatcher } from './saga/dashboard';
import { bookmarksSagaWatcher } from './saga/bookmarks';
import { inventorySagaWatcher } from './saga/inventory';
import { loginSagaWatcher } from './saga/login';
import { systemSagaWatcher } from './saga/system';
import { reportsSagaWatcher } from './saga/reports';
import { expensesSagaWatcher } from './saga/expenses';
import { amazonTrafficSagaWatcher } from './saga/amazonTraffic';
import { pnlDashboardSagaWatcher } from './saga/pnlDashboard';
import { advertisingSagaWatcher } from './saga/advertising';
import { shopifySagaWatcher } from './saga/shopify';
import { globalSystemNotificationSagaWatcher } from './saga/globalSystemNotification';
import { monthlyBrandReviewSagaWatcher } from './saga/monthlyBrandReview';
import { userFeedbackSagaWatcher } from './saga/userFeedback';
import { notificationsSagaWatcher } from 'seller/store/saga/userNotifications/saga';

const watchers = [
  refundsSagaWatcher,
  productsSagaWatcher,
  ordersSagaWatcher,
  bookmarksSagaWatcher,
  searchProductsSagaWatcher,
  dashboardSagaWatcher,
  emailToolSagaWatcher,
  userPreferencesSagaWatcher,
  inventorySagaWatcher,
  loginSagaWatcher,
  systemSagaWatcher,
  globalSystemNotificationSagaWatcher,
  reportsSagaWatcher,
  expensesSagaWatcher,
  amazonTrafficSagaWatcher,
  monthlyBrandReviewSagaWatcher,
  advertisingSagaWatcher,
  shopifySagaWatcher,
  pnlDashboardSagaWatcher,
  userFeedbackSagaWatcher,
  notificationsSagaWatcher
];

const sagaWatcher = getSagaWatcher(watchers, sendError);

export default sagaWatcher;
