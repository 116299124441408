export const SET_NOTES = 'SET_NOTES';
export const PULL_NOTES = 'PULL_NOTES';
export const PULL_SALES = 'PULL_SALES';
export const SET_SALES = 'SET_SALES';
export const PULL_PRIMARY_CHART_DATA = 'PULL_PRIMARY_CHART_DATA';
export const PULL_SECONDARY_CHART_DATA = 'PULL_SECONDARY_CHART_DATA';
export const SET_TOTALS = 'SET_TOTALS';
export const SET_REFUNDS_TOTALS = 'SET_REFUNDS_TOTALS';
export const PULL_TOTALS = 'PULL_TOTALS';
export const SET_DASHBOARD_ORDER_EVENT_TYPE = 'SET_DASHBOARD_ORDER_EVENT_TYPE';
export const SET_INSIGHTS_WIDGET_DATA = 'SET_INSIGHTS_WIDGET_DATA';
export const SET_INSIGHTS_WIDGET_LOADING = 'SET_INSIGHTS_WIDGET_LOADING';
export const PULL_INSIGHTS_WIDGET_DATA = 'PULL_INSIGHTS_WIDGET_DATA';

export const setNotesAction = data => ({
  type: SET_NOTES,
  data
});
export const pullNotesAction = payload => ({
  type: PULL_NOTES,
  payload
});

export const pullSalesAction = (params, compareParams) => ({
  type: PULL_SALES,
  params,
  compareParams
});

export const setSalesAction = (sales, compareSales) => ({
  type: SET_SALES,
  sales,
  compareSales
});

export const pullPrimaryChartDataAction = payload => ({
  type: PULL_PRIMARY_CHART_DATA,
  payload
});

export const pullSecondaryChartDataAction = payload => ({
  type: PULL_SECONDARY_CHART_DATA,
  payload
});

export const setRefundsTotalsAction = totals => ({
  type: SET_REFUNDS_TOTALS,
  totals
});

export const setTotalsAction = (totals, compareTotals) => ({
  type: SET_TOTALS,
  totals,
  compareTotals
});

export const pullTotalsAction = payload => ({
  type: PULL_TOTALS,
  payload
});

export const setDashboardOrderEventTypeAction = payload => ({
  type: SET_DASHBOARD_ORDER_EVENT_TYPE,
  payload
});

export const setInsightsWidgetDataAction = payload => ({
  type: SET_INSIGHTS_WIDGET_DATA,
  payload
});

export const setInsightsWidgetLoadingAction = payload => ({
  type: SET_INSIGHTS_WIDGET_LOADING,
  payload
});

export const pullInsightsWidgetDataAction = payload => ({
  type: PULL_INSIGHTS_WIDGET_DATA,
  payload
});
