import PropTypes from 'prop-types';
import { makeStyles, TextField } from '@material-ui/core';
import classNames from 'classnames';

import Colors from 'styles/colors';
import { OutlinedWrapper } from '@components/inputs';

export const textFieldStyles = makeStyles({
  root: {
    minWidth: '3rem',
    '& .MuiInput-underline:before': {
      borderBottomStyle: 'none !important'
    },
    '& .MuiInput-underline:after': {
      borderBottomStyle: 'none !important'
    },
    '& .MuiInput-root.Mui-error input': {
      color: Colors.red.default
    },
    '& input': {
      height: 'auto',
      padding: '0',
      fontSize: '0.875rem',
      color: Colors.gray.darkest
    },
    '& input::placeholder': {
      color: Colors.gray.dark,
      fontSize: '0.875rem'
    }
  }
});

const OutlinedTextField = props => {
  const textFieldClasses = textFieldStyles();
  const { title, wrapperClassName, ...childProps } = props;
  return (
    <OutlinedWrapper title={title} className={classNames(wrapperClassName, 'items-center')}>
      <TextField {...childProps} classes={textFieldClasses} autoComplete="new-password" />
    </OutlinedWrapper>
  );
};

OutlinedTextField.propTypes = {
  title: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string
};

OutlinedTextField.defaultProps = {
  wrapperClassName: ''
};

export default OutlinedTextField;
